import React, { Component } from 'react';
import {
    Row, Col,
    // Container,
    // Form, FormGroup, Label, Input
} from 'reactstrap';
import { pluralize } from '../../box-helpers';

class BoxOrder extends Component {
    state = {
        order: {},
        availableEnclosureQty: 0,
        availableDrillQty: 0,
        availableUvQty: 0
    }

    // constructor(props) {
    //     super(props)

    //     console.log('props', props.order)

    // }

    componentDidMount = () => {
        this.setQuantities();
    }

    setQuantities = () => {
        let totalEnclosureQty = this.props.order.enclosure_products.reduce((a,v) => {
            let maxQuantity = v.max_quantity >= 0 ? v.max_quantity : 0;
            a = a + maxQuantity;
            return a;
        }, 0);
        let totalDrillQty = this.props.order.drill_products.reduce((a,v) => a = a + v.available_quantity, 0)
        let totalUvQty = this.props.order.uv_products.reduce((a,v) => a = a + v.available_quantity, 0)
        this.setState({ availableEnclosureQty: totalEnclosureQty, availableDrillQty: totalDrillQty, availableUvQty: totalUvQty }) // availableDrillQty: totalDrillQty, availableUvQty: totalUvQty,
     }

    render() {
        return  (
            <div>
                { this.state.order && this.state.availableEnclosureQty > 0 && (
                <Row className="border-bottom">
                    <Col className="col" md={3} >
                        <div>{ this.props.order.order_number }</div>
                    </Col>
                    <Col className="col" md={4} >
                        <div>{ this.state.availableEnclosureQty } { pluralize("Enclosure", this.state.availableEnclosureQty) }</div>
                        <div>{ this.state.availableDrillQty } Drill { pluralize("Product", this.state.availableDrillQty) }</div>
                        <div>{ this.state.availableUvQty } Uv { pluralize("Product", this.state.availableUvQty) }</div>
                    </Col>
                    <Col className="col" md={2} >
                        <b>{this.props.order.status }</b>
                    </Col>
                    <Col className="col text-right" md={3} >
                        <a href={"/box-jobs/" + this.props.order.order_number} className="btn btn-outline-success btn-sm">Create Job</a>
                    </Col>
                </Row>
                )}
            </div>
        )
    }
}
export default BoxOrder;