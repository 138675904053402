import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Link from '@material-ui/core/Link';


import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import HomeIcon from '@material-ui/icons/Home';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const mainListItems = (
  <div className='custom-menu'>
    <Link href="/dashboard" className='menu-links'>
      <ListItem button>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link>
    <Link href="/my-drill-templates" className='menu-links'>
      <ListItem button>
        <ListItemIcon>
          <PictureInPictureIcon />
        </ListItemIcon>
        <ListItemText primary="Drill Templates" />
      </ListItem>
    </Link>
    <Link href="/my-uv-print-templates" className='menu-links'>
      <ListItem button>
        <ListItemIcon>
          <InsertPhotoIcon />
        </ListItemIcon>
        <ListItemText primary="UV Print Templates" />
      </ListItem>
    </Link>
    <Link href="/my-box-configurations" className='menu-links'>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Box Job Templates" />
      </ListItem>
    </Link>
  </div>
);