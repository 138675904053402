import React, { Component } from "react";

import Footer from "../Layout/Footer";
import BoxConfigurationPagination from "../Layout/BoxConfigurationPagination";

import OAuthService from '../Auth/OAuthService';

import {
    Row, Col,
    Container,
    Button,
    // Table
    Alert
} from 'reactstrap';
// import BoxOrder from "./BoxOrder";
// import BoxJob from "./BoxJob";

import { humanize } from '../../box-helpers'

class MyBoxConfigurations extends Component {
    constructor(props){
        super(props);
        this.os = new OAuthService();
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="mb-2 text-center">
                                <h3>Box Job Templates</h3>
                            </div>
                            <div>
                                <Alert color="success" className="text-left">
                                    <div className="text-success">
                                        <h5>Latest Features!</h5>
                                        <p></p>
                                    </div>
                                </Alert>
                                <a className="btn btn-sm btn-outline-primary float-right" href="/box-configurations/">+ New Box Job Template</a>
                                <div style={{ clear: 'both'}}></div>
                                <BoxConfigurationPagination
                                    page_size_key={"_boxConfigurationsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_configurations'}
                                    data_attr={"box_configurations"}
                                    redirect_to={"my-box-configurations"}
                                    show_archived={false}
                                    os={this.os}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default MyBoxConfigurations;