import React, { Component } from 'react';

import {
    Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input,
    Alert, Card, CardBody
} from 'reactstrap';
import moment from 'moment';
import Button from 'reactstrap/lib/Button';
// import { humanize } from '../../box-helpers';
// import Alert from 'reactstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-solid-svg-icons';

class BoxConfiguration extends Component {
    state = {
        box_configuration: {},
        openOrderModal: false,
        alertMsg: "",
        showAlert: false,
        rating: 0,
        comment: "",
        qty: 1,
        separateProducts: false,
        readyToOrder: false,
        showWarning: false,
        warningMsg: ""
    }

    constructor(props) {
        super(props)

        if (this.props.box_configuration) {
            this.state.box_configuration = this.props.box_configuration;
        }
        this.os = this.props.os;
    }

    componentDidMount = () => {
        this.getValidation(1);
    }

    checkQty = async (e) => {
        this.setState({ qty: e.target.value });
        // CAll API box_configurations/id/validate to check if the qty is valid
        if (e.target.value > 0) {
            this.getValidation(e.target.value);
        }
    }

    getValidation = async (qty, order_number) => {
        let token = JSON.parse(localStorage.getItem("token"));
        this.os.validateBoxConfiguration(token, this.state.box_configuration.id, qty, order_number).then((result) => {

            if (result.insufficient_products || result.insufficient_credits) {
                var alertMsg = result.required_products
                    .filter((product) => product.needed_qty > 0)
                    .map((product) => { return `${product.sku}, ${product.needed_qty}` })

                    alertMsg.unshift("Products Needed");
                this.setState({ showWarning: true, warningMsg: alertMsg.join('\n') });
            } else {
                this.setState({ showWarning: false, warningMsg: "", readyToOrder: true });
            }
        }).catch((error) => {
            console.log('error', error);
            this.setState({
                showAlert: true,
                alertMsg: "Error validating box_job " + error
            })
        })

    }

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
          console.log('Copied to clipboard');
        }).catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }

    getProductsDiv = (products) => {
        var allProductsText = products.map(product => `${product.sku}, ${this.state.qty}`).join('\n');
        return (
            <Card className="bg-light p-2">
                {products.length > 0 && (
                    <div>
                        <FontAwesomeIcon icon={faCopy} className='float-right' alt='Copy to Clipboard' title='Copy to Clipboard'
                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                            onClick={() => this.copyToClipboard(allProductsText)}
                        />
                        {products.map((product) => (
                            <div key={"boxConfigProduct" + this.state.box_configuration.id + "-" + product.id}>
                                <div>{product.sku},{this.state.qty}</div>
                            </div>
                        ))}
                    </div>
                )}
            </Card>
        )
    }

    showProductCopyDiv = () => {
        if (this.state.separateProducts) {
            var encl_products = this.state.box_configuration.products.filter((product) => product.service_type === null);
            var service_products = this.state.box_configuration.products.filter((product) => product.service_type !== null);
            return (
                <Row>
                    <Col className="col" md={6}>
                        <h5>Enclosure Products</h5>
                        {this.getProductsDiv(encl_products)}
                    </Col>
                    <Col className="col" md={6}>
                        <h5>Service Products</h5>
                        {this.getProductsDiv(service_products)}
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col className="col" md={6}>
                        <h5>All Products</h5>
                        {this.getProductsDiv(this.state.box_configuration.products)}
                    </Col>
                </Row>
            )
        }
    }

    toggleOrderModal = () => {
        this.setState({openOrderModal: !this.state.openOrderModal})
    }

    render() {
        return  (
            <div>
                { this.state.box_configuration  && (
                <Row key={"boxConfigRow" + this.state.box_configuration.id } className={ this.state.box_configuration.has_order ? "bg-warning" : "border-bottom" }>
                    <Col className="col" md={2}>
                        <div>{this.state.box_configuration.name }</div>
                    </Col>
                    <Col className="col" md={4}>
                        {this.state.box_configuration.product && (
                            <div>
                                {this.state.box_configuration.product.small_image_url && (
                                    <div className='float-left mr-4'>
                                        <img src={this.state.box_configuration.product.small_image_url} className="mx-1 w-100" style={{maxWidth: "60px"}} alt={this.state.box_configuration.name} />
                                    </div>
                                )}
                                <div>{this.state.box_configuration.product.sku} { this.state.box_configuration.product.name }</div>
                                <div>{this.state.box_configuration.enclosure_type} {this.state.box_configuration.powder_color}</div>
                            </div>
                        )}
                        <div className='clearfix'>
                            {this.state.box_configuration.available_order_items.length > 0 && (
                                <div className='alert alert-info m-0 py-1'>
                                    <b>Available Orders</b>
                                    {this.state.box_configuration.available_order_items.map((order_item) => (
                                        <div key={"boxConfigOrder" + this.state.box_configuration.id + "-" + order_item.id}>
                                            <div>Order#: <b>{order_item.order_number}</b> - Available Qty: {order_item.available_qty}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {this.state.box_configuration.available_product_credits.length > 0 && (
                                <div className='alert alert-info m-0 py-1'>
                                    <b>Available Product Credits</b>
                                    {this.state.box_configuration.available_product_credits.map((credit) => (
                                        <div key={"boxConfigCredit" + this.state.box_configuration.id + "-" + credit.id}>
                                            <div>Product Credit: <b>{credit.product_sku}</b> Available Qty: {credit.credit_balance}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col className="col" md={2}>
                        {this.state.box_configuration.unfinished_box_jobs.length > 0 && (
                            <div>
                                <b>In Progress Orders</b>
                                {this.state.box_configuration.unfinished_box_jobs.map((job) => (
                                    <div key={"boxConfigJob" + this.state.box_configuration.id + "-" + job.id}>
                                        <div>Order#: <b>{job.order_number}</b> Qty: <b>{job.quantity}</b></div>
                                        <div style={{whiteSpace: 'pre'}}>
                                            {job.status}
                                            {job.has_flagged && (
                                                <span className="text-danger">Flagged</span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Col>
                    <Col className="col" md={2}>
                        <b>{moment(this.state.box_configuration.created_at).format("MMM DD YYYY h:mm A") }</b>
                    </Col>
                    <Col className="col" md={1}>
                        {/* // Create a link that triggers a Popup with the box configuration details */}
                        <Button color="primary" size="sm" block className="my-1 px-0" onClick={() => this.setState({openOrderModal: !this.state.openOrderModal})}>Quick Order</Button>
                        <Modal style={{top: '60px'}} isOpen={this.state.openOrderModal} toggle={this.toggleOrderModal}>
                            <ModalHeader toggle={() => this.setState({openOrderModal: !this.state.openOrderModal})}>Order Box Job - {this.state.box_configuration.name}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="qty">How many Box Jobs do you want?</Label>
                                        <Input name="qty" id="qty" onChange={this.checkQty} value={this.state.qty} type="number" />
                                    </FormGroup>
                                </Form>
                                {this.state.box_configuration.products.length > 0 && (
                                    <div>
                                        {this.showProductCopyDiv()}
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" checked={this.state.separateProducts} onChange={() => this.setState({separateProducts: !this.state.separateProducts})} />{' '}
                                                Separate Enclosure and Service Products
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )}
                                <Alert color="info" className="my-2" isOpen={true}>
                                    <h5 style={{fontSize: "18px"}}>Tayda Electronics Quick Order Form</h5>
                                    <div>
                                        <div>1. Copy the product list above.</div>
                                        <div className='px-3'>You can purchase service products in a separate order if desired.</div>
                                        <div>2. Go to <a href="https://www.taydaelectronics.com/quick-order/" target="_blank" rel="noopener noreferrer">Tayda Electronics Quick Order page</a></div>
                                        <div className='px-3'><a href="https://www.taydaelectronics.com/quick-order/" target="_blank" rel="noopener noreferrer">www.taydaelectronics.com/quick-order</a></div>
                                        <div>3. Paste the product list in the form</div>
                                    </div>
                                </Alert>

                                <Alert color="danger" isOpen={this.state.showAlert}>{this.state.alertMsg}</Alert>
                            </ModalBody>
                            <ModalFooter>
                                <Alert color="warning" isOpen={this.state.showWarning}>
                                    {this.state.warningMsg.split("\n").map((line, index) => (
                                        <>
                                        {line}
                                        <br />
                                        </>
                                    ))}
                                </Alert>
                                <Button color="primary" onClick={this.applyOrder} disabled={!this.state.readyToOrder} >Order</Button>{' '}
                                <Button color="secondary" onClick={() => this.setState({openOrderModal: !this.state.openOrderModal})}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                        <a href={"/box-configurations?id=" + this.state.box_configuration.id} className="btn btn-outline-success btn-sm">Edit</a>
                    </Col>

                </Row>
                )}

            </div>
        )
    }
}
export default BoxConfiguration;