import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { humanize } from '../../box-helpers';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Card,
    Container,
    FormGroup,
    Input,
    Col,
    Alert,
    Button,
    Collapse,
    Row,
    Form,
    Label
} from 'reactstrap';
import Select from 'react-select';
import Switch from "react-switch";
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/pro-solid-svg-icons';

import {getValidEnclosureTypes} from '../../box-helpers';
import { BorderLeft } from '@material-ui/icons';
import OAuthService from '../Auth/OAuthService';

class CustomerBoxConfigurationForm extends Component {
  state = {
    loading: false,
    alertMsg: "",
    showAlert: false,
    collapse: false,
    boxTypes: getValidEnclosureTypes(),
    colors: [],
    usePredrilledEnclosure: false,
    box_designs: [], box_uv_designs: [],
    enclosureProducts: [],
    drillProducts: [],
    uvProducts: [],
    serviceProducts: [],
    boxConfigurationId: "",
    configurationName: "",
    boxType: "",
    powderColor: "",
    selectedEnclosureProduct: {id: "", name: "", img: ""},
    selectedDrillDesign: {id: "", name: ""},
    addDrillServices: false,
    addUVPrintServices: false,
    uvSidesCount: 1,
    selectedDrillProducts: [{id: null}],
    selectedUVPrints: [{id: null}],
    selectedProducts: [],
    readyToSave: false,

    orderBoxJob: false,
    readyToOrder: false,
    box_configuration: null,
    openOrderModal: false,
    qty: 1, separateProducts: false,
    warningMsg: '',
  }

  constructor(props) {
    super(props);

    this.os = new OAuthService();
  }

  componentDidMount = () => {
    // Check if box_configuration_id is passed in the URL
    // If so, fetch the box configuration and set the state
    // If not, set the state to default values
    const parsed = queryString.parse(window.location.search);
    if(parsed.id){
      this.setState({
          loading: true
      }, () => {
          this.setBoxConfiguration(parsed.id)
      })
    }
    if (parsed.orderBoxJob) {
      this.setState({ orderBoxJob: true });
    }

  }


  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleCheckBoxesChange = (e) => {
    if(e.target.checked){
      this.setState({
        [e.target.name]: e.target.checked
      })
    }else{
      var curr_selectedProducts = JSON.parse(JSON.stringify(this.state.selectedProducts));
      if(e.target.name == "addDrillServices"){
        var drill_prodcuts = curr_selectedProducts.filter(p => p.is_drill_product === true && !p.is_predrill)
        var new_curr_selectedProducts = curr_selectedProducts.filter(a => !drill_prodcuts.includes(a))
        curr_selectedProducts = new_curr_selectedProducts

        this.setState({
          selectedProducts: curr_selectedProducts
        })

      }else if(e.target.name == "addUVPrintServices"){
        var uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)
        var new_curr_selectedProducts = curr_selectedProducts.filter(a => !uv_products.includes(a))
        curr_selectedProducts = new_curr_selectedProducts

        this.setState({
          selectedProducts: curr_selectedProducts,
          uvSidesCount: 1
        })
      }
      this.setState({
        [e.target.name]: e.target.checked,
      })
    }
  }

  handleUsePredrilledEnclosureChange = (new_switch_state) => {
    this.setState({
      usePredrilledEnclosure: new_switch_state,
    }, () => {
      this.handleEnclosureChange(null);
    })
  }

  handleAddDrillServicesChange = (new_switch_state) => {
    if(new_switch_state){
      this.setState({
        addDrillServices: new_switch_state,
        selectedDrillDesign: {id: "", name: ""}
      })
    }else{
      var curr_selectedProducts = JSON.parse(JSON.stringify(this.state.selectedProducts));
      var drill_prodcuts = curr_selectedProducts.filter(p => p.is_drill_product === true && !p.is_predrill)
      var new_curr_selectedProducts = curr_selectedProducts.filter(a => !drill_prodcuts.includes(a))
      curr_selectedProducts = new_curr_selectedProducts

      this.setState({
        addDrillServices: new_switch_state,
        selectedProducts: curr_selectedProducts,
        selectedDrillDesign: {id: "", name: ""}
      })
    }
  }

  handleAddUVPrintServicesChange = (new_switch_state) => {
    if(new_switch_state){
      this.setState({
        addUVPrintServices: new_switch_state,
        selectedUVPrints: [{id: null}]
      }, () => {
        var box_uv_designs = this.getUVOptions();
        if(box_uv_designs.length == 1){
          this.handleUVPrintChangeSelect({value: box_uv_designs[0].value, label: box_uv_designs[0].box_side, name: "uvPrint" }, 0);
        }
      })
    }else{
      var curr_selectedProducts = JSON.parse(JSON.stringify(this.state.selectedProducts));
      var uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)
      var new_curr_selectedProducts = curr_selectedProducts.filter(a => !uv_products.includes(a))
      curr_selectedProducts = new_curr_selectedProducts

      this.setState({
        selectedProducts: curr_selectedProducts,
        uvSidesCount: 1,
        addUVPrintServices: new_switch_state,
        selectedUVPrints: [{id: null}]
      })
    }
  }

  handleBoxTypeChangeSelect = (val) => {
    if(val == null){
      this.setState({
        boxType: "",
        powderColor: "",
        selectedEnclosureProduct: {id: "", name: "", img: ""},
        selectedDrillDesign: {id: "", name : ""},
        colors: [],
        box_designs: [],
        box_uv_designs: [],
        enclosureProducts: [],
        addDrillServices: false,
        addUVPrintServices: false,
        uvSidesCount: 1,
        selectedProducts: [],
        selectedDrillProducts: [{id: null}],
        selectedUVPrints: [{id: null}],
      })
    }else{
      if (val.value) {
        this.setState({
          boxType: val.value,
          powderColor: "",
          selectedEnclosureProduct: {id: "", name: "", img: ""},
          selectedDrillDesign: {id: "", name : ""},
          colors: [],
          box_designs: [],
          box_uv_designs: [],
          enclosureProducts: [],
          addDrillServices: false,
          addUVPrintServices: false,
          uvSidesCount: 1,
          selectedProducts: [],
          selectedDrillProducts: [{id: null}],
          selectedUVPrints: [{id: null}],
        })
        this.getPowderColors(val.value);
      }
    }
  }

  handlePowderColorChangeSelect = (val) => {
    if(val == null){
      this.setState({
        powderColor: "",
        selectedEnclosureProduct: {id: "", name: "", img: ""},
        selectedDrillDesign: {id: "", name : ""},
        enclosureProducts: [],
        uvSidesCount: 1,
        addDrillServices: false,
        addUVPrintServices: false,
        selectedProducts: [],
        selectedUVPrints: [{id: null}],
      })
      return
    }else{
      let value = val.value
      if (value) {
        this.setState({
          powderColor: value,
          selectedEnclosureProduct: {id: "", name: "", img: ""},
          selectedDrillDesign: {id: "", name : ""},
          enclosureProducts: [],
          uvSidesCount: 1,
          addDrillServices: false,
          addUVPrintServices: false,
          selectedProducts: [],
          selectedUVPrints: [{id: null}],
        })
        this.fetchAvailableProducts(this.state.boxType, value);
      }
    }
  }

  handleClick = (e) => {
    this.setState({ readyToSave: true });

    if (this.state.configurationName !== "") {
      this.handleSubmit(e);
    } else {
      this.setState({ alertMsg: "Please enter a configuration name", showAlert: true });
    }
  }

  setBoxConfiguration = async (id) => {
    console.log('get box_configuration id', id);
    var result =  await this.submitGetRequest(process.env.REACT_APP_API_URL + '/api/v4/box_configurations/' + id);

    if(result["errors"]){
      console.log('result error: ',result["errors"]);
      let errors = "";
      if (typeof result["errors"] === 'string') {
        errors = result["errors"];
      } else {
        errors = Object.keys(result["errors"]).map(key => {
          let human_key = humanize(key);
          return  human_key + " " + result["errors"][key];
        })
      }
      this.setState({ alertMsg: errors, showAlert: true, loading: false})
    }else{
      var box_configuration = result["box_configuration"];
      console.log('box_configuration', box_configuration);
      if (box_configuration) {
        let selectedEnclosureProduct = {id: box_configuration.product.id, name: box_configuration.product.sku + " " + box_configuration.product.name, img: box_configuration.product.small_image_url}
        this.setState({
          boxConfigurationId: box_configuration.id,
          configurationName: box_configuration.name,
          boxType: box_configuration.enclosure_type,
          powderColor: box_configuration.powder_color,
          selectedEnclosureProduct: selectedEnclosureProduct,
          readyToSave: true,
          // selectedDrillDesign: {id: "", name : ""},
          // uvSidesCount: 1,
          // addDrillServices: false,
          // addUVPrintServices: false,
          // selectedProducts: [],
          // selectedUVPrints: [{id: null}],
        }, () => {
          // Set the selected products and selected drill products and design ids
          let {selectedProducts, uvSidesCount, addDrillServices, addUVPrintServices, selectedDrillDesign} = this.state;
          let selectedUVPrints = [];
          let selectedDrillProducts = [];

          //selectedProducts.push(selectedEnclosureProduct);
          let cur_selected_enclosure_products = box_configuration.products.filter(p => p.enclosure_type === this.state.boxType && p.powder_color && p.powder_color !== '');
          if(cur_selected_enclosure_products.length > 0){
            selectedProducts.push(cur_selected_enclosure_products[0]);
          }

          box_configuration.box_configuration_items.forEach(item => {
            /*
            var curr_item = {
              id: item.product.id,
              name: item.product.sku + " " + item.product.name,
              img: item.product.small_image_url,
              service_type: item.product_type,
              product_type: item.product_type,
              enclosure_type: item.product.enclosure_type,
              powder_color: item.product.powder_color,
              is_drill_product: item.product.is_drill_product,
              is_uv_product: item.product.is_uv_product,
              is_service_product: item.product.is_service_product,
              is_predrill: item.product.is_predrill,
              is_lid_product: item.product.is_lid_product,
              TEMPLATE_SELECT: item.design,
            }
            */
            var curr_item = item.product
            if(item.design){
              curr_item.TEMPLATE_SELECT = item.design
            }

            if (item.product_type === "drill") {
              selectedDrillDesign = {id: item.design_id, name: item.design.name}
              addDrillServices = true;
              selectedDrillProducts.push({id: item.product.id});
            } else if (item.product_type === "uv") {
              uvSidesCount += 1;
              addUVPrintServices = true;
              let selectedUvServices = [];

              curr_item.SERVICE_SELECT = [];
              item.box_configuration_item_services.forEach(service => {
                selectedUvServices.push({product_id: service.product.id, service_type: service.service_type});
                curr_item.SERVICE_SELECT.push(service.product);
              });

              var uv_print = item.product;
              if(item.design){
                uv_print.TEMPLATE_SELECT = item.design;
              }
              selectedUVPrints.push(uv_print); // services: selectedUvServices
            }
            selectedProducts.push(curr_item);
          });
          console.log('selectedProducts', selectedProducts);
          this.setState({ selectedProducts, uvSidesCount, addDrillServices, addUVPrintServices, selectedDrillProducts, selectedUVPrints, selectedDrillDesign });
          this.fetchAvailableProducts(box_configuration.enclosure_type, box_configuration.powder_color);
          this.getPowderColors(box_configuration.enclosure_type);
        });
      }
    }
  }

  getEnclosureProduct = () => {
    var enclosure_product = this.state.enclosureProducts.filter(a => a.is_predrill === this.state.usePredrilledEnclosure || (a.is_predrill == null && !this.state.usePredrilledEnclosure))
    return enclosure_product.map((t) => ({ value: t.id, label: t.sku + " " + t.name, name: "enclosureProduct", img: t.small_image_url, obj: t }))
  }

  handleEnclosureChange = (val) => {
    var enclosure_product = this.state.enclosureProducts.filter(a => a.is_predrill === this.state.usePredrilledEnclosure || (a.is_predrill == null && !this.state.usePredrilledEnclosure))

    if(val == null){
      if (this.state.boxConfigurationId === "") {
        this.setState({
          selectedEnclosureProduct: enclosure_product.length === 1 ? {id:  enclosure_product[0].id, name: enclosure_product[0].sku + " " + enclosure_product[0].name, img: enclosure_product[0].small_image_url} : {id: "", name: "", img: ""},
          selectedDrillDesign: {id: "", name : ""},
          uvSidesCount: 1,
          addDrillServices: false,
          addUVPrintServices: false,
          selectedProducts: enclosure_product.length == 1 ? [enclosure_product[0]] : [],
          selectedDrillProducts: [{id: null}],
          selectedUVPrints: [{id: null}],
        })
      }
    }else{
      if (val.value) {
        this.setState({
          selectedEnclosureProduct: {id: val.value, name: val.label, img: val.img},
          selectedDrillDesign: {id: "", name : ""},
          uvSidesCount: 1,
          addDrillServices: false,
          addUVPrintServices: false,
          selectedProducts: [val.obj],
          selectedDrillProducts: [{id: null}],
          selectedUVPrints: [{id: null}],
        })
      }
    }
  }

  handleAddDrillServicesCheckboxesChange = (e, id) => {
    if(e.target.checked){
      var drill_product_index = this.state.drillProducts.findIndex(a => a.id == id);
      if(drill_product_index != -1){
        var curr_selectedProducts = JSON.parse(JSON.stringify(this.state.selectedProducts));
        curr_selectedProducts.push(this.state.drillProducts[drill_product_index])
        this.setState({
          selectedProducts: curr_selectedProducts,
          selectedDrillDesign: {id: "", name : ""}
        })
      }
    }else{
      var curr_selectedProducts = JSON.parse(JSON.stringify(this.state.selectedProducts));
      var drill_product_index = curr_selectedProducts.findIndex(a => a.id == id);
      if(drill_product_index != -1){
        curr_selectedProducts.splice(drill_product_index, 1);
        this.setState({
          selectedProducts: curr_selectedProducts,
          selectedDrillDesign: {id: "", name : ""}
        })
      }
    }
  }

  getDrillDesign = () => {
    var selected_drill_prodcuts = this.state.selectedProducts.filter(p => p.is_drill_product === true && !p.is_predrill)
    var box_designs = []
    if(selected_drill_prodcuts.length > 1){
      /*
      selected_drill_prodcuts.forEach(a => {
        if(a.is_lid_product == true){
          var curr_box_designs = this.state.box_designs.filter(b => b.has_lid == true)
          box_designs = box_designs.concat(curr_box_designs);
        }else if(a.is_lid_product == null || a.is_lid_product == false){
          var curr_box_designs = this.state.box_designs.filter(b => b.has_lid == false)
          box_designs = box_designs.concat(curr_box_designs);
        }
      })
      */
      box_designs = this.state.box_designs.filter(b => b.has_lid == true)
    }else if(selected_drill_prodcuts.length == 1){
      if(selected_drill_prodcuts[0].is_lid_product == true){
        box_designs = this.state.box_designs.filter(b => b.lid_only == true)

      }else if(selected_drill_prodcuts[0].is_lid_product == null || selected_drill_prodcuts[0].is_lid_product == false){
        box_designs = this.state.box_designs.filter(b => b.has_lid == false)
      }
    }
    var sorted_box_designs = box_designs.sort((a, b) => b.id - a.id);
    return sorted_box_designs.map((t) => ({ value: t.id, label: t.name, name: "boxDesign", obj: t }))
  }

  handleSelectDrillDesignChangeSelect = (val) => {
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));
    var selected_drill_prodcuts = curr_selectedProducts.filter(p => p.is_drill_product === true && !p.is_predrill)
    selected_drill_prodcuts.forEach(a => {
      var selected_drill_prodcut_index = curr_selectedProducts.findIndex(b => b.id == a.id);
      if(selected_drill_prodcut_index > -1){
        if(val == null){
          curr_selectedProducts[selected_drill_prodcut_index].TEMPLATE_SELECT = null;
        }else{
          curr_selectedProducts[selected_drill_prodcut_index].TEMPLATE_SELECT = val.obj;
        }
      }
    })

    if(val == null){
      this.setState({
        selectedDrillDesign: {id: "", name : ""},
        selectedProducts: curr_selectedProducts
      })
    }else{
      if (val.value) {
        this.setState({
          selectedDrillDesign: val.obj,
          selectedProducts: curr_selectedProducts
        })
      }
    }
  }

  handleUVPrintChangeSelect = (val, index) => {
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));
    if(val == null){
      var uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)
      if(uv_products.length > 1){
        uv_products.splice(index, 1);
        var new_curr_selectedProducts = curr_selectedProducts.filter(a => !uv_products.includes(a))
        curr_selectedProducts = new_curr_selectedProducts
      }else{
        var new_curr_selectedProducts = curr_selectedProducts.filter(a => !uv_products.includes(a))
        curr_selectedProducts = new_curr_selectedProducts
      }

      var new_curr_selectedUVPrints = JSON.parse(JSON.stringify(this.state.selectedUVPrints));
      new_curr_selectedUVPrints[index] = {id: null}

      this.setState({
        selectedProducts: curr_selectedProducts,
        selectedUVPrints: new_curr_selectedUVPrints,
      })

    }else{
      var uv_product_index = this.state.uvProducts.findIndex(a => a.id == val.value);
      if(uv_product_index != -1){
        var uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)
        if(uv_products.length > 0 && uv_products.length > index){
          var old_uv_product_id = uv_products[index].id;
          var old_uv_product_index = curr_selectedProducts.findIndex(a => a.id == old_uv_product_id);
          if(old_uv_product_index > -1){
            curr_selectedProducts.splice(old_uv_product_index, 1);
          }
        }
        curr_selectedProducts.push(this.state.uvProducts[uv_product_index])

        var new_curr_selectedUVPrints = JSON.parse(JSON.stringify(this.state.selectedUVPrints));
        new_curr_selectedUVPrints[index] = this.state.uvProducts[uv_product_index]

        this.setState({
          selectedProducts: curr_selectedProducts,
          selectedUVPrints: new_curr_selectedUVPrints,
        })
      }
    }
  }

  handleUVPrintTemplateChangeSelect = (val, index) => {
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));
    var selected_uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)

    //var curr_selected_uv_products_index = curr_selectedProducts.findIndex(a => a.id == selected_uv_products[index].id);
    var curr_selected_uv_products_index = curr_selectedProducts.length - selected_uv_products.length + index;

    var new_curr_selectedUVPrints = JSON.parse(JSON.stringify((this.state.selectedUVPrints)));

    if(val == null){
      curr_selectedProducts[curr_selected_uv_products_index].TEMPLATE_SELECT = null;
      new_curr_selectedUVPrints[index].TEMPLATE_SELECT = null;
    }else{
      curr_selectedProducts[curr_selected_uv_products_index].TEMPLATE_SELECT = val.obj;
      new_curr_selectedUVPrints[index].TEMPLATE_SELECT = val.obj;
    }

    this.setState({
      selectedProducts: curr_selectedProducts,
      selectedUVPrints: new_curr_selectedUVPrints
    })
  }

  handleUVPrintOptionsCheckboxesChange = (e, type, index, obj) => {
    //test
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));
    var selected_uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)

    //var curr_selected_uv_products_index = curr_selectedProducts.findIndex(a => a.id == selected_uv_products[index].id);
    var curr_selected_uv_products_index = curr_selectedProducts.length - selected_uv_products.length + index;

    if(!curr_selectedProducts[curr_selected_uv_products_index]["SERVICE_SELECT"]){
      curr_selectedProducts[curr_selected_uv_products_index].SERVICE_SELECT = []
    }
    var curr_SERVICE_SELECT = curr_selectedProducts[curr_selected_uv_products_index].SERVICE_SELECT;

    if(e.target.checked){
      curr_SERVICE_SELECT.push(obj);
      curr_selectedProducts[curr_selected_uv_products_index].SERVICE_SELECT = curr_SERVICE_SELECT;
    }else{
      var curr_obj_index = curr_SERVICE_SELECT.findIndex(a => a.id == obj.id);
      if(curr_obj_index > -1){
        if(curr_SERVICE_SELECT.length > 1){
          curr_SERVICE_SELECT.splice(curr_obj_index, 1);
          curr_selectedProducts[curr_selected_uv_products_index].SERVICE_SELECT = curr_SERVICE_SELECT;
        }else{
          curr_SERVICE_SELECT = [];
          curr_selectedProducts[curr_selected_uv_products_index].SERVICE_SELECT = curr_SERVICE_SELECT;
        }
      }
    }

    this.setState({
      selectedProducts: curr_selectedProducts,
    })
  }

  toggleAlert = () => {
    this.setState({ showAlert: !this.state.showAlert });
  };

  getUVPrintOptionsCheckboxes = (index, product) => {
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));
    var selected_uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)

    if(selected_uv_products[index] && selected_uv_products[index]["SERVICE_SELECT"]){
      if(selected_uv_products[index].SERVICE_SELECT.length > 0){
        var curr_product_index = selected_uv_products[index].SERVICE_SELECT.findIndex(a => a.id == product.id);
        if(curr_product_index > -1){
          return true;
        }else{
          return false;
        }
      } else {
        return false;
      }
    }else{
      return false;
    }
  }

  getDisabledUVPrintOptionsCheckboxes = (index) => {
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));
    var selected_uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)

    if(selected_uv_products[index]){
      return false;
    }else{
      return true;
    }
  }

  getPowderColors = (box_type) => {
    let colors = [];

    if (box_type) {
      this.setState({
        loading: true
      }, async () => {
        console.log('fetching colors for box_type', box_type);
        var result =  await this.submitGetRequest(process.env.REACT_APP_API_URL + '/api/v4/products/powder_colors?enclosure_type=' + box_type);

        if(result["errors"]){
          console.log('result error: ',result["errors"]);
          let errors = "";
          if (typeof result["errors"] === 'string') {
            errors = result["errors"];
          } else {
            errors = Object.keys(result["errors"]).map(key => {
              let human_key = humanize(key);
              return  human_key + " " + result["errors"][key];
            })
          }
          this.setState({ alertMsg: errors, showAlert: true, loading: false})
        }else{
          colors = result;
          this.setState({ colors: colors });
          this.getBoxDesigns(box_type);
          this.getBoxUVDesigns(box_type);
        }
      })
    }
  }

  getBoxDesigns = (box_type) => {
    let box_designs = [];

    this.setState({
      loading: true
    }, async () => {
      var result =  await this.submitGetRequest(process.env.REACT_APP_API_URL + '/api/v4/box_designs?enclosure_type=' + box_type);

      if(result["errors"]){
        console.log('result error: ',result["errors"]);
        let errors = "";
        if (typeof result["errors"] === 'string') {
          errors = result["errors"];
        } else {
          errors = Object.keys(result["errors"]).map(key => {
            let human_key = humanize(key);
            return  human_key + " " + result["errors"][key];
          })
        }
        this.setState({ alertMsg: errors, showAlert: true, loading: false})
      }else{
        box_designs = result["box_designs"] ? result.box_designs : [];
        this.setState({ box_designs: box_designs, loading: false });
      }
    })
  }

  getBoxUVDesigns = (box_type) => {
    let box_uv_designs = [];

    this.setState({
      loading: true
    }, async () => {
      var result =  await this.submitGetRequest(process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs?enclosure_type=' + box_type);

      if(result["errors"]){
        console.log('result error: ',result["errors"]);
        let errors = "";
        if (typeof result["errors"] === 'string') {
          errors = result["errors"];
        } else {
          errors = Object.keys(result["errors"]).map(key => {
            let human_key = humanize(key);
            return  human_key + " " + result["errors"][key];
          })
        }
        this.setState({ alertMsg: errors, showAlert: true, loading: false})
      }else{
        box_uv_designs = result["box_uv_designs"] ? result.box_uv_designs : [];
        this.setState({ box_uv_designs: box_uv_designs, loading: false });
      }
    })
  }

  fetchAvailableProducts = async (box_type, powder_color) => {
    let products = [];
    if (box_type && powder_color) {
      this.setState({
        loading: true
      }, async ()=> {
        try {
          console.log('fetching products for box_type', box_type, 'powder_color', powder_color);
          const result = await this.submitGetRequest(`${process.env.REACT_APP_API_URL}/api/v4/products?enclosure_type=${box_type}&powder_color=${powder_color}`);

          if (result.errors) {
            console.log('result error: ', result.errors);
            let errors = "";
            if (typeof result.errors === 'string') {
              errors = result.errors;
            } else {
              errors = Object.keys(result.errors).map(key => {
                let human_key = humanize(key);
                return human_key + " " + result.errors[key];
              }).join(", ");
            }
            this.setState({ alertMsg: errors, showAlert: true, loading: false });
          } else {
            products = result;

            let enclosureProducts = products.filter(p => p.enclosure_type === box_type && p.powder_color && p.powder_color !== '');
            let drillProducts = products.filter(p => p.is_drill_product === true && !p.is_predrill);
            let uvProducts = products.filter(p => p.is_uv_product === true);
            let serviceProducts = products.filter(p => p.is_service_product === true);
            console.log('drillProducts', drillProducts);
            console.log('uvProducts', uvProducts);
            console.log('serviceProducts', serviceProducts);
            console.log('enclosureProducts', enclosureProducts);

            this.setState({ enclosureProducts, drillProducts, uvProducts, serviceProducts, loading: false, collapse: false }, () => {
              this.handleEnclosureChange(null);
            });
          }
        } catch (error) {
          console.log('error', error);
          this.setState({ showAlert: true, alertMsg: error.message });
        }
      });
    }
  }

  orderBoxJob = (e) => {
    this.setState({ readyToSave: true, orderBoxJob: true });

    // if (this.state.configurationName !== "") {
      this.handleSubmit(e, true);
    // } else {
    //   this.setState({ alertMsg: "Please enter a configuration name", showAlert: true });
    // }
  }

  handleSubmit = (e, orderBoxJob) => {
    e.preventDefault();

    /*
    box_configuration": {
      "name": "My Job  ",
      "enclosure_type": "1590BB",
      "powder_color": "MATTE BLACK",
      "product_id": "5850",
      "box_configuration_items_attributes": [
      {
        "product_id": "6202",
        "product_type": "drill",
        "design_id": 1
      },
      {
        "product_id": "6509",
        "product_type": "uv",
        "design_id": 2,
        "box_configuration_item_services_attributes": [
          {
            "product_id": "6980",
            "service_type": "gloss"
          }
        ]
      }]
    }
    */
    var cur_selected_products = JSON.parse(JSON.stringify(this.state.selectedProducts));
    let cur_selected_enclosure_products = cur_selected_products.filter(p => p.enclosure_type === this.state.boxType && p.powder_color && p.powder_color !== '');
    let cur_selected_drill_products = cur_selected_products.filter(p => p.is_drill_product === true && !p.is_predrill);
    let cur_selected_uv_products = cur_selected_products.filter(p => p.is_uv_product === true);

    let body = {
      "name": this.state.configurationName,
      "enclosure_type": cur_selected_enclosure_products[0].enclosure_type,
      "powder_color": cur_selected_enclosure_products[0].powder_color,
      "product_id": cur_selected_enclosure_products[0].id,
    }

    if(cur_selected_drill_products.length > 0 || cur_selected_uv_products.length > 0){
      body.box_configuration_items_attributes = []
    }

    if(cur_selected_drill_products.length > 0){
      cur_selected_drill_products.forEach(a => {
        if(a.TEMPLATE_SELECT != null){
          var drill = {
            "product_id": a.id,
            "product_type": a.service_type,
            "design_id": a.TEMPLATE_SELECT.id
          }
          body.box_configuration_items_attributes.push(drill)
        }
      })
    }

    if(cur_selected_uv_products.length > 0){
      cur_selected_uv_products.forEach(a => {
        if(a.TEMPLATE_SELECT != null){
          var uv = {
            "product_id": a.id,
            "product_type": a.service_type,
            "design_id": a.TEMPLATE_SELECT.id,
            "box_configuration_item_services_attributes": []
          }
          if(a.SERVICE_SELECT.length > 0){
            a.SERVICE_SELECT.forEach(b => {
              uv.box_configuration_item_services_attributes.push({
                "product_id": b.id,
                "service_type": b.service_type
              })
            })
          }

          body.box_configuration_items_attributes.push(uv)
        }
      })
    }

    this.setState({
      loading: true
    }, async ()=> {
      try {
        let result = null;
        if (this.state.boxConfigurationId !== "") {
          result = await this.submitPostRequest(`${process.env.REACT_APP_API_URL}/api/v4/box_configurations/${this.state.boxConfigurationId}`, body, true);
        } else {
          result = await this.submitPostRequest(`${process.env.REACT_APP_API_URL}/api/v4/box_configurations`, body);
        }
        if (result.errors) {
          console.log('result error: ', result.errors);
          let errors = "";
          if (typeof result.errors === 'string') {
            errors = result.errors;
          } else {
            errors = Object.keys(result.errors).map(key => {
              let human_key = humanize(key);
              return human_key + " " + result.errors[key];
            }).join(", ");
          }
          this.setState({ alertMsg: errors, showAlert: true, loading: false });
        } else {
          console.log('result', result);

          let box_configuration = result.box_configuration;
          console.log('box_configuration', box_configuration);

          if(this.state.orderBoxJob){
            this.setState({ loading: true, showAlert: false, orderBoxJob: false, box_configuration: box_configuration }, () => {
              //console.log(this.state);
              //this.setState({ openOrderModal: true })
              this.getValidation(1);
            });
          }else{
            this.setState({ loading: false, showAlert: false, orderBoxJob: false, openOrderModal: false, box_configuration: null});
          }

          if (box_configuration) {
            const history = createBrowserHistory();
            let params = '?id=' + box_configuration.id;
            if (orderBoxJob === true) {
              params += '&orderBoxJob=true';
            }
            history.push('/box-configurations' + params);
          }
        }
      } catch (error) {
        console.log('error', error);
        this.setState({ showAlert: true, alertMsg: error.message, loading: false, orderBoxJob: false });
      }
    });
  }

  submitGetRequest = async (url) => {
    let token = JSON.parse(localStorage.getItem("token"));
    var result =  await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log('data', data);
      return data;
    })
    .catch((error) => {
      console.log('error', error);
      this.setState({ showAlert: true, alertMsg: error.message });
      return error.message;
    });
    return result;
  }

  submitPostRequest = async (url, body, isPut) => {
    let token = JSON.parse(localStorage.getItem("token"));
    var result =  await fetch(url, {
      method: isPut ? 'PUT' : 'POST',
      headers: {
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      console.log('data', data);
      return data;
    })
    .catch((error) => {
      console.log('error', error);
      this.setState({ showAlert: true, alertMsg: error.message });
      return error.message;
    });
    return result;
  }

  getSelectedDrillProduct = (product_id) => {
    var drillProduct = this.state.drillProducts.find(a => a.id == product_id);
    var selectedProduct = this.state.selectedProducts.find(a => a.id == product_id);
    return !!(selectedProduct && drillProduct);
  }

  addUVSideCounts = () => {
    var new_curr_selectedUVPrints = JSON.parse(JSON.stringify(this.state.selectedUVPrints));
    new_curr_selectedUVPrints.push({id: null})
    this.setState({
      uvSidesCount: this.state.uvSidesCount + 1,
      selectedUVPrints: new_curr_selectedUVPrints
    }, () => {
      var box_uv_designs = this.getUVOptions();
      if(box_uv_designs.length == 1){
        this.handleUVPrintChangeSelect({value: box_uv_designs[0].value, label: box_uv_designs[0].box_side, name: "uvPrint" }, (this.state.uvSidesCount - 1));
      }
    })
  }

  minusUVSideCounts = (index) => {
    var new_curr_selectedUVPrints = JSON.parse(JSON.stringify(this.state.selectedUVPrints));
    var curr_selectedProducts = JSON.parse(JSON.stringify((this.state.selectedProducts)));

    var selected_uv_products = curr_selectedProducts.filter(p =>  p.is_uv_product === true)
    var old_uv_product_index = curr_selectedProducts.length - selected_uv_products.length + index;

    curr_selectedProducts.splice(old_uv_product_index, 1);

    new_curr_selectedUVPrints.splice(index, 1);
    this.setState({
      uvSidesCount: this.state.uvSidesCount - 1,
      selectedProducts: curr_selectedProducts,
      selectedUVPrints: new_curr_selectedUVPrints,
    })
  }

  getUVSelectedValue = (index) => {
    var curr_selectedUVPrints = JSON.parse(JSON.stringify(this.state.selectedUVPrints));
    var uv_product_id = curr_selectedUVPrints[index].id;

    if(uv_product_id == null){
      return {value: "", label: ""}
    }else{
      var uv_product_index = this.state.uvProducts.findIndex(a => a.id == uv_product_id)
      if(uv_product_index > -1){
        return {value: this.state.uvProducts[uv_product_index].id, label: this.state.uvProducts[uv_product_index].box_side}
      }
    }
  }

  getUVOptions = () => {
    // this.state.uvProducts.forEach(a => {
    // map the uvProducts to the product id as value and box)sides as label
    return this.state.uvProducts.map((t) => ({ value: t.id, label: t.box_side, name: "uvPrint" }))
    // var uvProducts = [];
    // this.state.uvProducts.forEach(a => {
    //   var filter_box_uv_design = this.state.box_uv_designs.filter(b => b.box_side == a.box_side);
    //   if(filter_box_uv_design.length > 0){
    //     uvProducts.push(a);
    //   }
    // })

    // return uvProducts.map((t) => ({ value: t.id, label: t.box_side, name: "uvPrint" }))
    //return this.state.uvProducts.map((t) => ({ value: t.id, label: t.box_side, name: "uvPrint" }))
  }

  getUVSideBoxDesigns = (index) => {
    var curr_selectedUVPrints = JSON.parse(JSON.stringify(this.state.selectedUVPrints));
    var uv_product_id = curr_selectedUVPrints[index].id;

    if(uv_product_id == null || uv_product_id == ""){
      return []
    }else{
      var uv_product_index = this.state.uvProducts.findIndex(a => a.id == uv_product_id)
      var selected_uv_box_side = this.state.uvProducts[uv_product_index].box_side
      var box_uv_designs = this.state.box_uv_designs.filter(a => a.box_side.includes(selected_uv_box_side))
      return box_uv_designs.map((t) => ({ value: t.id, label: t.name, name: "boxUVDesigns", obj: t }));
    }
  }

  getValidation = async (qty, order_number) => {
    let token = JSON.parse(localStorage.getItem("token"));
    this.os.validateBoxConfiguration(token, this.state.box_configuration.id, qty, order_number).then((result) => {
      if (result.insufficient_products || result.insufficient_credits) {
        var alertMsg = result.required_products
          .filter((product) => product.needed_qty > 0)
          .map((product) => { return `${product.sku}, ${product.needed_qty}` })

          alertMsg.unshift("Products Needed");
          this.setState({ loading: false, showWarning: true, warningMsg: alertMsg.join('\n'), openOrderModal: true });
      }
    }).catch((error) => {
      console.log('error', error);
      this.setState({
        loading: false,
        showAlert: true,
        alertMsg: "Error validating box_job " + error
      })
    })
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied to clipboard');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  }

  getProductsDiv = (products) => {
    var allProductsText = products.map(product => `${product.sku}, ${this.state.qty}`).join('\n');
    return (
      <Card className="bg-light p-2">
        {products.length > 0 && (
          <div>
            <FontAwesomeIcon icon={faCopy} className='float-right' alt='Copy to Clipboard' title='Copy to Clipboard'
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={() => this.copyToClipboard(allProductsText)}
            />
            {products.map((product) => (
              <div key={"boxConfigProduct" + this.state.box_configuration.id + "-" + product.id}>
                <div>{product.sku},{this.state.qty}</div>
              </div>
            ))}
          </div>
        )}
      </Card>
    )
  }

  showProductCopyDiv = () => {
    if (this.state.separateProducts) {
      var encl_products = this.state.box_configuration.products.filter((product) => product.service_type === null);
      var service_products = this.state.box_configuration.products.filter((product) => product.service_type !== null);
      return (
        <Row>
          <Col className="col" md={6}>
            <h5>Enclosure Products</h5>
            {this.getProductsDiv(encl_products)}
          </Col>
          <Col className="col" md={6}>
            <h5>Service Products</h5>
            {this.getProductsDiv(service_products)}
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col className="col" md={6}>
            <h5>All Products</h5>
            {this.getProductsDiv(this.state.box_configuration.products)}
          </Col>
        </Row>
      )
    }
  }

  toggleOrderModal = () => {
    this.setState({openOrderModal: !this.state.openOrderModal})
  }

  render() {
      return (
        <Container fluid className="m-auto px-5 pb-4">
          <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
          />

          {this.state.box_configuration != null &&
            <Modal style={{top: '60px'}} isOpen={this.state.openOrderModal} toggle={this.toggleOrderModal}>
              <ModalHeader toggle={() => this.setState({openOrderModal: !this.state.openOrderModal})}>Order Box Job - {this.state.box_configuration.name}</ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for="qty">How many Box Jobs do you want?</Label>
                    <Input name="qty" id="qty" onChange={this.checkQty} value={this.state.qty} type="number" />
                  </FormGroup>
                </Form>
                {this.state.box_configuration.products.length > 0 && (
                  <div>
                    {this.showProductCopyDiv()}
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" checked={this.state.separateProducts} onChange={() => this.setState({separateProducts: !this.state.separateProducts})} />{' '}
                        Separate Enclosure and Service Products
                      </Label>
                    </FormGroup>
                  </div>
                )}
                <Alert color="info" className="my-2" isOpen={true}>
                  <h5 style={{fontSize: "18px"}}>Order Products Tayda Electronics Quick Order Form</h5>
                  <div>
                    <div>1. Copy the product list above.</div>
                    <div className='px-3'>You can purchase service products in a separate order if desired.</div>
                    <div>2. Go to <a href="https://www.taydaelectronics.com/quick-order/" target="_blank" rel="noopener noreferrer">Tayda Electronics Quick Order page</a></div>
                    <div className='px-3'><a href="https://www.taydaelectronics.com/quick-order/" target="_blank" rel="noopener noreferrer">www.taydaelectronics.com/quick-order</a></div>
                    <div>3. Paste the product list in the form</div>
                  </div>
                </Alert>
                <Alert color="warning" isOpen={this.state.showWarning}>
                  {this.state.warningMsg.split("\n").map((line, index) => (
                    <>
                    {line}
                    <br />
                    </>
                  ))}
                </Alert>
                <Alert color="danger" isOpen={this.state.showAlert}>{this.state.alertMsg}</Alert>
              </ModalBody>
              <ModalFooter>
                  <Button color="primary" onClick={this.applyOrder} disabled={!this.state.readyToOrder} >Order</Button>{' '}
                  <Button color="secondary" onClick={() => this.setState({openOrderModal: !this.state.openOrderModal})}>Cancel</Button>
              </ModalFooter>
            </Modal>
          }

          { this.state.loading && (
              <div className="loading style-2">
                  <div className="loading-wheel"></div>
                  <h3 className="loading-message">THIS MIGHT TAKE A FEW MINUTES. PLEASE WAIT...</h3>
              </div>
          )}

          {!this.state.success && (
            <form action="" className="edit-configuration" onSubmit={this.handleSubmit}>
              <Row>
                <Col className="col" md={7}>
                  <h2 className="row p-2 bg-inverse mb-1">Create a Box Job</h2>
                  <h5 className="row p-2 bg-secondary text-white mb-0">1. Choose your Enclosure<span style={{color: "red"}}>*</span></h5>
                  <Row className="pb-3" style={{border: "1px solid hsl(0,0%,80%)"}}>
                    <Col md="12">
                      <FormGroup row>
                        <Col className="col" md={12}>
                          <label className="col-form-label">Select Enclosure Type</label>
                          <Select
                            key="boxType"
                            name="boxType"
                            isClearable={true}
                            value={{ value: this.state.boxType, label: this.state.boxType}}
                            onChange={this.handleBoxTypeChangeSelect}
                            options={this.state.boxTypes.map((t) => ({ value: t, label: t, name: "boxType" }))}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col className="col" md={12}>
                          <label className="col-form-label">Select Enclosure Color</label>
                          <Select
                            key="powderColor"
                            name="powderColor"
                            isClearable={true}
                            value={{ value: this.state.powderColor, label: this.state.powderColor}}
                            onChange={this.handlePowderColorChangeSelect}
                            options={this.state.colors.map((t) => ({ value: t, label: t, name: "powderColor" }))}
                            isDisabled={this.state.colors.length > 0 ? false : true}
                          />
                          <Input type="hidden" name="productId" key="productId" value={this.state.productId}  />
                        </Col>
                      </FormGroup>
                      <Row className="py-4">
                        <Col md="auto" className="d-flex align-items-center">
                          Are you using Predrilled Enclosures?
                        </Col>
                        <Col>
                          <Switch onChange={this.handleUsePredrilledEnclosureChange} checked={this.state.usePredrilledEnclosure}
                          />
                        </Col>
                      </Row>
                      <FormGroup row>
                        <Col md={12} className="col">
                          <label className="col-form-label">Select Enclosure Product</label>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        {this.state.selectedEnclosureProduct.id != "" &&
                          <Col md={4} className="col d-flex align-items-center">
                            <img src={this.state.selectedEnclosureProduct.img} className="mx-1 w-100" style={{maxWidth: "80px"}} alt={this.state.selectedEnclosureProduct.name} />
                          </Col>
                        }
                        <Col md={this.state.selectedEnclosureProduct.id != "" ? 8 : 12} className="col d-flex align-items-center">
                          <Select
                            className="w-100"
                            key="enclosureProduct"
                            name="enclosureProduct"
                            isClearable={true}
                            value={{ value: this.state.selectedEnclosureProduct.id, label: this.state.selectedEnclosureProduct.name}}
                            onChange={this.handleEnclosureChange}
                            options={this.getEnclosureProduct()}
                            isDisabled={this.state.enclosureProducts.length > 0 ? false : true}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.selectedEnclosureProduct.id != "" && (this.state.drillProducts.length > 0 || this.state.uvProducts.length > 0) &&
                  <>
                    <h5 className="row p-2 bg-secondary text-white mb-0">2. Choose your Services</h5>
                    {!this.state.usePredrilledEnclosure &&
                      <Row className="px-2 py-3" md={12} style={{border: "1px solid hsl(0,0%,80%)"}}>
                        <Col className="p-0">
                        {this.state.drillProducts.length > 0 &&
                          <Container className="px-1">
                            <Row>
                              <Col className="d-flex align-items-center">
                                <h5 className='mb-0'>Add Drill Services?</h5>
                              </Col>
                              <Col md="auto">
                                <Switch onChange={this.handleAddDrillServicesChange} checked={this.state.addDrillServices} />
                              </Col>
                            </Row>
                          </Container>
                        }
                        {this.state.addDrillServices &&
                          <Container className="px-1">
                            <hr />
                            <h6><b>Choose Custom Drill Serives</b></h6>
                            {this.state.drillProducts.map((product, index) => (
                              <FormGroup check key={"drillProduct"+index}>
                                <Container>
                                  <Row>
                                    <Col md={12} className="col d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id={"addDrillServices-" + product.id}
                                        name={"addDrillServices-" + product.id}
                                        value={product.id}
                                        checked={this.getSelectedDrillProduct(product.id)}
                                        onChange={e => this.handleAddDrillServicesCheckboxesChange(e, product.id)}
                                      />
                                      { product.small_image_url &&
                                        <img src={product.small_image_url} className='m-2' style={{width: "50px"}} alt={product.name} />
                                      }
                                      <Label check for={"addDrillServices-" + product.id} >
                                        {product.name}
                                      </Label>
                                    </Col>
                                  </Row>
                                </Container>
                              </FormGroup>
                            ))}
                          </Container>
                        }
                        {this.state.addDrillServices && this.state.box_designs.length > 0 && this.getDrillDesign().length > 0 &&
                          <Container className="px-1">
                            <hr />
                            <FormGroup row>
                              <Col className="col" md={12}>
                                <label className="col-form-label"><h6><b>Select Drill Template</b></h6></label>
                                <Select
                                  key="boxDesigns"
                                  name="boxDesigns"
                                  isClearable={true}
                                  value={{ value: this.state.selectedDrillDesign.id, label: this.state.selectedDrillDesign.name}}
                                  onChange={this.handleSelectDrillDesignChangeSelect}
                                  options={this.getDrillDesign()}
                                />
                              </Col>
                            </FormGroup>
                          </Container>
                        }
                        </Col>
                      </Row>
                    }

                    <Row className="px-2 py-3" md={12} style={{borderTop: !this.state.usePredrilledEnclosure ? "5px solid hsl(0,0%,80%)" : "1px solid hsl(0,0%,80%)", borderLeft: "1px solid hsl(0,0%,80%)", borderRight: "1px solid hsl(0,0%,80%)", borderBottom: "1px solid hsl(0,0%,80%)"}}>
                      <Col className="p-0">
                        {this.state.uvProducts.length > 0 &&
                          <Container className="px-1">
                            <Row>
                              <Col className="d-flex align-items-center">
                                <h5 className='mb-0'>Add UV Print Service?</h5>
                              </Col>
                              <Col md="auto">
                                <Switch onChange={this.handleAddUVPrintServicesChange} checked={this.state.addUVPrintServices} />
                              </Col>
                            </Row>
                          </Container>
                        }
                        {this.state.addUVPrintServices &&
                          <Container className="px-1">
                            <hr />
                            <h6><b>Choose Custom UV Print Serives</b></h6>
                            {this.state.selectedUVPrints.map((object, index) => (
                              <div key={"uvProduct"+index}>
                                <FormGroup row className="py-2">
                                  <Col className="col" md={this.getUVSideBoxDesigns(index).length > 0 ? 4 : 12}>
                                    <label className="col-form-label w-100">
                                      <Container>
                                        <Row>
                                          {this.state.selectedUVPrints.length > 1 &&
                                            <Col md={"auto"} className="text-center" onClick={e => this.minusUVSideCounts(index)}>
                                              <FontAwesomeIcon className="fa-lg" icon={faTrash} color="#41464b" />
                                            </Col>
                                          }
                                          <Col className="p-0">
                                            <span className='p-1 bg-secondary text-white' style={{borderRadius: "5px"}}>
                                              <b>UV Service {index+1}</b>
                                            </span>
                                          </Col>
                                        </Row>
                                      </Container>
                                    </label>
                                    <Select
                                      key="uvPrint"
                                      name="uvPrint"
                                      isClearable={true} //teest
                                      value={object.id ? { value: object.id, label: object.box_side } : null}
                                      onChange={e => this.handleUVPrintChangeSelect(e, index)}
                                      options={this.getUVOptions()}
                                    />
                                  </Col>
                                  {this.getUVSideBoxDesigns(index).length > 0 &&
                                    <Col className="col" md={8}>
                                      <label className="col-form-label w-100">
                                        <Container>
                                          <Row>
                                            <Col className="p-0">
                                              <h6 className='mb-0'><b>Select UV Service {index+1} Template</b></h6>
                                            </Col>
                                          </Row>
                                        </Container>
                                      </label>
                                      <Select
                                        key="boxUvDesigns"
                                        name="boxUvDesigns"
                                        isClearable={true}
                                        // value={this.getUVSelectedValue(index)}
                                        value={object.TEMPLATE_SELECT ? {value: object.TEMPLATE_SELECT.id, label: object.TEMPLATE_SELECT.name} : null}
                                        onChange={e => this.handleUVPrintTemplateChangeSelect(e, index)}
                                        options={this.getUVSideBoxDesigns(index)}
                                      />
                                    </Col>
                                  }
                                </FormGroup>
                                <Row className="mt-4">
                                  <Col>
                                    Add UV Print Options
                                  </Col>
                                </Row>
                                { this.state.serviceProducts.map((product, idx) => ( //test
                                  <FormGroup check row key={"serviceProduct"+index+"-"+idx} className="pt-2 pb-1 ml-1">
                                    <Col className="col" md={12}>
                                      {this.getUVPrintOptionsCheckboxes(index, product) == true &&
                                        <Input id={"extra-" + product.name + "-layer-" + index} type="checkbox" onChange={e => this.handleUVPrintOptionsCheckboxesChange(e, product.name, index, product)} defaultChecked={true} disabled={this.getDisabledUVPrintOptionsCheckboxes(index)} />
                                      }
                                      {this.getUVPrintOptionsCheckboxes(index, product) == false &&
                                        <Input id={"extra-" + product.name + "-layer-" + index} type="checkbox" onChange={e => this.handleUVPrintOptionsCheckboxesChange(e, product.name, index, product)} defaultChecked={false} disabled={this.getDisabledUVPrintOptionsCheckboxes(index)} />
                                      }
                                      <label htmlFor={"extra-" + product.name + "-layer-" + index} className="col-form-label p-0">{product.name}</label>
                                    </Col>
                                  </FormGroup>
                                ))}
                                {index < (this.state.selectedUVPrints.length - 1) && <hr />}
                              </div>
                            ))}
                            <hr />
                            <Row className="py-3">
                              <Col className="text-center">
                                <Button onClick={e => this.addUVSideCounts()}>+ Add UV Side</Button>
                              </Col>
                            </Row>
                          </Container>
                        }
                      </Col>
                    </Row>
                  </>
                  }
                  {this.state.readyToSave && (
                    <div>
                      <h5 className="row p-2 bg-secondary text-white mb-0">Box Configuration Name<span style={{color: "red"}}>*</span></h5>
                      <Row className="pb-3" style={{border: "1px solid hsl(0,0%,80%)"}}>
                        <Col md="12">
                        <FormGroup row>
                          <Col className="col mt-3" md={12}>
                            <Input
                              key="configurationName"
                              id="configurationName"
                              name="configurationName"
                              placeholder="Box Configuration Name"
                              onChange={this.handleInputChange}
                              value={this.state.configurationName}
                            />
                          </Col>
                        </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Col className="mt-2 text-center">
                      <Alert color="danger" isOpen={this.state.showAlert} toggle={this.state.toggleAlert}>{this.state.alertMsg}</Alert>
                      <Button color="success" onClick={e => this.handleClick(e)}>Save Box Job Later</Button>
                    </Col>
                  </Row>
                </Col>

                <Col className="col px-0" md={5}>
                  <div className='ml-4' style={{position: "sticky", top: "66px", border: "1px solid hsl(0,0%,80%)"}}>
                    <h2 className="p-2 text-white" style={{backgroundColor: "#89CFF0"}}>My Order</h2>

                      <FormGroup row>
                        <Col className="col" md={12}>
                          <Container>
                            {this.state.selectedProducts.length > 0 && this.state.selectedProducts.map((product, index) => (product && (
                              <div key={index}>
                              <div>
                                <Row className="py-0 justify-content-center align-items-center">
                                  <Col md={4} className="text-center">
                                    {product.small_image_url &&
                                      <img src={product.small_image_url} className="mx-1 w-100" alt={product.name} style={{maxWidth: "80px"}} />
                                    }
                                  </Col>
                                  <Col>
                                    <div>{product.sku} {product.name}</div>
                                    <div>{product["TEMPLATE_SELECT"] ? "- " +  product["TEMPLATE_SELECT"].name : ""}</div>
                                    {product["SERVICE_SELECT"] && product["SERVICE_SELECT"].map((service, index) => (service && (
                                      <div key={"service-" + index}><span style={{textTransform: "capitalize"}}>{"- " + service.name}</span></div>
                                    )))}
                                  </Col>
                                </Row>
                              </div>
                              {index < (this.state.selectedProducts.length - 1) && <hr />}
                              </div>
                            )))}
                            <Row>
                              <Col>
                                <div className='m-2 p-2' style={{background: "silver"}}>
                                  <h5 className='m-0 p-0'>
                                    Once you have selected all your services for your Box Job, Click "Order This Box Job" button to set quantity and get a product list to order
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-2 text-center">
                                <Button color="primary" onClick={e => this.orderBoxJob(e)} disabled={( this.state.selectedProducts.length > 0) ? false : true}>Order This Box Job</Button>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </FormGroup>
                  </div>
                </Col>
              </Row>
            </form>
          )}
      </Container>
    )
  }
}

export default CustomerBoxConfigurationForm;